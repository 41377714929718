<template>
  <div class="slideshow">
    <figure class="slide">
      <div class="image-container">
        <img :src="getImgUrl(currentImage.src)"
             :alt="currentImage.alt"
             :style="imageStyle"
             :class="portrait? currentImage.positionX:currentImage.positionY"
             @load="calculateImageStyle"
             ref="image"
        >
      </div>
      <figcaption class="caption">{{ currentImage.caption }}</figcaption>
    </figure>
    <menu class="slideshow-controls">
      <li id="pre-button" class="control-button"><button @click="showPrevImage" aria-label="Afficher l'image précédente"><font-awesome-icon icon="fa-solid fa-arrow-left" /></button></li>
      <li id="next-button" class="control-button"><button @click="showNextImage" aria-label="Afficher l'image suivante"><font-awesome-icon icon="fa-solid fa-arrow-right" /></button></li>
    </menu>
  </div>
</template>

<style scoped>
.slideshow {
  max-width: 100vw;
  height: max(480px, calc(100svh - 8rem));
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.slide {
  margin: 0;
  height: inherit;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.image-container {
  position: relative;
  max-height: max(480px, calc(100svh - 8rem));
  width: 100%;
  overflow: hidden;
}

.caption {
  color: v-bind(smoothWhite);
  text-shadow: 0 0 20px v-bind(smoothBlack);
  opacity: 0;
  transition: opacity 0.5s ease;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
}

.slideshow-controls {
display: flex;
list-style: none;
justify-content: space-between;
width: 100%;
}

.control-button {
  position: absolute;
  bottom: 50%;
  flex-grow: 1;
  text-align: center;
}

#pre-button {
  opacity: 0;
  left: 1rem;
  transition: opacity 0.5s ease;
}

#next-button {
  opacity: 0;
  right: 1rem;
  transition: opacity 0.5s ease;
}

.control-button button {
  width: 4rem;
  height: 4rem;
  border: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: v-bind(smoothBlack);
  color: v-bind(smoothWhite);
  font-size: 1.5rem;
  text-decoration: none;
  cursor: pointer;
}

.slideshow:hover #pre-button,
.slideshow:hover #next-button{
opacity: 0.4;
}

#pre-button:hover,
#next-button:hover{
  opacity: 1 !important;
}

.slideshow:hover .caption {
  opacity: 1;
}
@media screen and (orientation: portrait) {
  .image-container .droite {
    transform: translateX(max(-10vh, -10%));
  }
  .image-container .gauche {
    transform: translateX(min(10vh, 10%));
  }
}
@media screen and (orientation: landscape) {
  .image-container .haut{
    transform: translateY(min(10vw, 10%));
  }
  .image-container .bas{
    transform: translateY(max(-10vw, -10%));
  }
}
</style>

<script>
export default {
  name: 'SlideshowPresentation',
  data() {
    return {
      electricBlue: "#4EBBC2",
      darkElectricBlue: "#557F82",
      smoothBlack: "#1F2222",
      smoothWhite: "#FFF9F3",
      images: [
        {
          src: 'image_01.webp',
          alt: 'Image 1',
          caption: 'Dans le studio',
          positionX: 'droite',
          positionY: 'bas'
        },
        {
          src: 'image_02.webp',
          alt: 'Image 2',
          caption: 'La configuration du studio 1',
          positionX: '',
          positionY: 'bas'
        },
        {
          src: 'image_03.webp',
          alt: 'Image 3',
          caption: 'En plein mixage au studio',
          positionX: '',
          positionY: 'bas'
        },
        {
          src: 'image_04.webp',
          alt: 'Image 4',
          caption: 'La configuration du studio 2',
          positionX: '',
          positionY: 'bas'
        },
        {
          src: 'image_05.webp',
          alt: 'Image 5',
          caption: 'Lors d\'une séance d\'enregistrement',
          positionX: '',
          positionY: ''
        },
        {
          src: 'image_06.webp',
          alt: 'Image 6',
          caption: 'Durant un mixage sur scène',
          positionX: 'gauche',
          positionY: 'bas'
        }
      ],
      currentImageIndex: 0,
      imageStyle: {},
      portrait: false
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentImageIndex];
    }
  },
  methods: {
    calculateImageStyle() {
      // Calculez la hauteur de la page à 82% de la hauteur de la fenêtre
      const pageHeight = window.innerHeight * 0.82;

      // Calculez le rapport de 100% de la largeur de la page par rapport à la hauteur de la page
      const containerRatio = (window.innerWidth / pageHeight);
      // console.log(containerRatio);

      // Calculez le ratio de l'image
      const img = this.$refs.image;
      const imgRatio = img.width / img.height;
      // console.log(imgRatio);

      if (imgRatio < containerRatio) {
        // Si le ratio de l'image est plus petit, ajustez la largeur
        this.imageStyle = {
          width: '100%',
          height: 'auto',
        };
        this.portrait=false;
      } else {
        // Sinon, ajustez la hauteur
        this.imageStyle = {
          width: 'auto',
          height: '100%',
        };
        this.portrait=true;
      }
    },
    showPrevImage() {
      this.currentImageIndex = this.currentImageIndex === 0 ? this.images.length - 1 : this.currentImageIndex - 1;
      this.calculateImageStyle();
      this.resetInterval()
    },
    showNextImage() {
      this.currentImageIndex = this.currentImageIndex === this.images.length - 1 ? 0 : this.currentImageIndex + 1;
      this.calculateImageStyle();
      this.resetInterval()
    },
    getImgUrl(a) {
      return require(`@/assets/photo/${a}`);
    },
    resetInterval() {
      clearInterval(this.interval);
      this.calculateImageStyle();
      this.interval = setInterval(this.showNextImage, 10000);
    }
  },
  mounted() {
    // Démarrez un intervalle qui change l'image toutes les 10 secondes
    this.interval = setInterval(this.showNextImage, 10000);
    // Appelez calculateImageStyle() lorsque le composant est monté
    this.calculateImageStyle();
    // Ajoutez un écouteur d'événement de redimensionnement de la fenêtre
    window.addEventListener('resize', this.calculateImageStyle);
  },
  beforeUnmount() {
    // Nettoyez l'intervalle lorsque le composant est détruit
    clearInterval(this.interval);
    // Retirez l'écouteur d'événement lorsque le composant est détruit
    window.removeEventListener('resize', this.calculateImageStyle);
  },
};
</script>