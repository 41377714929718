<script>
export default {
  name: 'ContactForm',
  data() {
    return {
      website: "davidjmixing.com"
    }
  }
}
</script>

<template>
  <div>
    <h2>Mentions légales</h2>
    <h3>1. Présentation du site</h3>
    <p>Conformément aux dispositions des articles 6-III et 19 de la Loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l'économie numérique, nous vous informons que :</p>
    <ul>
      <li>Le site <strong>{{website}}</strong> est édité par :</li>
      <ul>
        <li><strong>Éditeur :</strong> Sebald Pauer (pauer.sebald@hotmail.fr)</li>
        <li><strong>Directeur de la publication :</strong> David Jakoubovitch</li>
        <li><strong>Adresse :</strong> Shmu'El HaNavi 18 Israel</li>
        <li><strong>Téléphone :</strong> +972542201984</li>
      </ul>
    </ul>
    <h3>2. Hébergement</h3>
    <p>Le site est hébergé par :</p>
    <ul>
      <li><strong>Nom de l'hébergeur :</strong> o2switch</li>
      <li><strong>Adresse :</strong> Chemin des Pardiaux 63000 Clermont-Ferrand</li>
      <li><strong>Contact :</strong> 04.44.44.60.40 ou support@o2switch.fr</li>
    </ul>
    <h3>3. Conditions générales d'utilisation du site et des services proposés</h3>
    <p>L'utilisation du site <strong>{{ website }}</strong> implique l'acceptation pleine et entière des conditions générales d'utilisation ci-après décrites. Ces conditions d'utilisation sont susceptibles d'être modifiées ou complétées à tout moment, les utilisateurs du site <strong>{{website}}</strong> sont donc invités à les consulter de manière régulière.</p>
    <h3>4. Propriété intellectuelle et contrefaçons</h3>
    <p>{{website}} est propriétaire des droits de propriété intellectuelle ou détient les droits d'usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels.</p>
    <p>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de : {{website}}.</p>
    <h3>5. Limitations de responsabilité</h3>
    <p>{{website}} ne pourra être tenue responsable des dommages directs et indirects causés au matériel de l'utilisateur, lors de l'accès au site {{website}}, et résultant soit de l'utilisation d'un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l'apparition d'un bug ou d'une incompatibilité.</p>
    <h3>6. Gestion des données personnelles</h3>
    <p>En Israël, les données personnelles sont protégées par la loi israélienne. Sur le site <strong>{{website}}</strong>, le propriétaire ne collecte des informations personnelles relatives à l'utilisateur que pour le besoin de certains services proposés par le site. L'utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est alors précisé à l'utilisateur du site <strong>{{website}}</strong> l'obligation ou non de fournir ces informations.</p>
    <p>Conformément aux dispositions de la loi, tout utilisateur dispose d'un droit d'accès, de rectification et d'opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d'une copie du titre d'identité avec signature du titulaire de la pièce, en précisant l'adresse à laquelle la réponse doit être envoyée.</p>
    <h3>7. Liens hypertextes et cookies</h3>
    <p>Le site <strong>{{website}}</strong> contient un certain nombre de liens hypertextes vers d'autres sites, mis en place avec l'autorisation de {{website}}. Cependant, {{website}} n'a pas la possibilité de vérifier le contenu des sites ainsi visités, et n'assumera en conséquence aucune responsabilité de ce fait.</p>
    <p>La navigation sur le site <strong>{{website}}</strong> est susceptible de provoquer l'installation de cookie(s) sur l'ordinateur de l'utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l'identification de l'utilisateur, mais qui enregistre des informations relatives à la navigation d'un ordinateur sur un site.</p>
    <h3>8. Droit applicable et attribution de juridiction</h3>
    <p>Tout litige en relation avec l'utilisation du site <strong>{{website}}</strong> est soumis au droit israélien. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Jérusalem.</p>
    <h3>9. Les principales lois concernées</h3>
    <p>Lois applicables :</p>
    <ul>
      <li>Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique.</li>
      <li>Loi israélienne de protection de la vie privée, 5741-1981.</li>
    </ul>
    <h3>10. Lexique</h3>
    <p><strong>Utilisateur</strong> : Internaute se connectant, utilisant le site susnommé.</p>
    <p><strong>Informations personnelles</strong> : « les informations qui permettent, sous quelque forme que ce soit, directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).</p>
  </div>
</template>

<style scoped>
p{
  text-align: left;
  margin: 1rem 0;
}
</style>