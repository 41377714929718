<template>
  <div class="contact-form">
    <h2>Demandez votre devis personnalisé:</h2>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="name">Votre nom et prénom:</label>
        <input type="text" id="name" v-model="formData.name" required />
      </div>
      <div class="form-group">
        <label for="email">Votre e-mail:</label>
        <input type="email" id="email" v-model="formData.email" required />
      </div>
      <div class="form-group">
        <label for="style">Quel est votre style musical?</label>
        <input type="text" id="style" v-model="formData.style" required />
      </div>
      <div class="form-group">
        <label for="quantity">Combien de pistes doivent être prises en charge?</label>
        <input type="number" id="quantity" v-model="formData.quantity" required />
      </div>
      <div class="form-group">
        <label for="request">Votre projet:</label>
        <textarea id="request" v-model="formData.request" required></textarea>
      </div>
      <p class="requiered_warn"><i>Ces informations sont nécessaires.</i></p>
      <button :disabled="!(this.formData.name&&this.formData.email&&this.formData.style&&this.formData.request)" type="submit">
        {{ messageSuccess? "Message envoyé!":"Envoyer" }}</button>
      <p v-if="messageError.value">{{ "Une erreur est survenue:" + messageError.text }}</p>
    </form>
    <div id="alternate">
      <h2 aria-label="Alternative: WhatsApp">ou</h2>
      <div>
        <a aria-label="Contacter par WhatsApp" href="https://wa.me/972542201984">
          <svg id="whatsapp" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>
          <span>WhatsApp</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'ContactForm',
  data() {
    return {
      electricBlue: "#4EBBC2",
      darkElectricBlue: "#557F82",
      smoothBlack: "#1F2222",
      smoothWhite: "#FFF9F3",
      formData: {
        name: '',
        email: '',
        style: '',
        quantity: 1,
        request: ''
      },
      messageSuccess: false,
      messageError: {
        value: false,
        text: ''
      }
    };
  },
  methods: {
    submitForm() {
      emailjs.send("service_eukhgde","template_dys7ilc", this.formData, "zJIJfYJNJ3qdv0r46")
          .then(() => {
            console.log('E-mail envoyé avec succès !');
            this.messageSuccess = true;
            this.messageError.value = false;
          })
          .catch(error => {
            console.error('Erreur lors de l\'envoi de l\'e-mail:', error);
            this.messageSuccess = false;
            this.messageError.value = true;
            this.messageError.text = error;
          });
    },
  }
};
</script>

<style>
form, form div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form{
  gap: 1rem;
}
h2, label, figcaption {
  color: v-bind(smoothWhite);
}
label{
  margin-bottom: .8rem;
  text-align-last: center;
}
label::after{
  content: '*';
  color: red;
  font-family: 'Raleway', sans-serif;
  font-size: 1em;
}
.requiered_warn::before{
  content: '(*) ';
  color: red;
  font-family: 'Raleway', sans-serif;
  font-size: 1em;
}
input[type="text"], input[type="number"], input[type="email"], select{
  width: 75vw;
  height: 3rem;
  background-color: v-bind(smoothWhite);
  caret-color: v-bind(electricBlue);
  padding: 0 1rem;
}
textarea {
  width: 75vw;
  height: 9rem;
  background-color: v-bind(smoothWhite);
  caret-color: v-bind(electricBlue);
  padding: 1rem;
}
button[type="submit"] {
  cursor: pointer;
  margin-top: .8rem;
  color: v-bind(smoothBlack);
  background-color: v-bind(electricBlue);
  height: 5rem;
  width: 10rem;
  border: 0;
  border-radius: 1rem;
  transition: all 0.5s ease-in-out;
}
button[type="submit"]:disabled {
  color: v-bind(smoothWhite);
  background-color: v-bind(darkElectricBlue);
  cursor: not-allowed;
}
#alternate{
  padding: 2rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
#alternate div{
  width: fit-content;
}
#alternate a{
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: v-bind(smoothWhite);
  text-decoration: none;
}
#alternate a span{
  color: v-bind(darkElectricBlue);
  font-weight: bold;
}
#alternate a:hover{
  background-color: v-bind(electricBlue);
  transition: background-color 0.5s ease-in-out;
}
#alternate a:hover span{
  color: v-bind(smoothWhite);
  transition: color 0.5s ease-in-out;
}
#alternate a:hover #whatsapp path{
  fill: v-bind(smoothWhite);
  transition: fill 0.5s ease-in-out;
}
#whatsapp{
  height: 2rem;
  width: 2rem;
}
#whatsapp path{
  fill: v-bind(darkElectricBlue);
}
</style>
