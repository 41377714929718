<template>
  <div>
    <div id="reward_container">
      <figure class="reward_content">
        <img :src="getImgUrl('disque-platine-logo.webp')" alt="Logo du disque de platine" loading="lazy">
        <figcaption>{{contentArrayDisk.platine + " disque" + (contentArrayDisk.platine > 1 ? 's': '') + " de platine"}}</figcaption>
      </figure>
      <figure class="reward_content">
        <img :src="getImgUrl('disque-or-logo.webp')" alt="Logo du disque d'or" loading="lazy">
        <figcaption>{{ contentArrayDisk.or + " disque" + (contentArrayDisk.or > 1 ? 's': '') + " d'or"}}</figcaption>
      </figure>
    </div>
    <h2>Parcours</h2>
    <div id="bio_container">
      <div class="bio_content">
          <p class="bio_content_text">
            <strong>David Jakoubovitch</strong> est un ingénieur du son certifié ({{contentArrayDisk.or}} disques d'or et {{contentArrayDisk.platine}} de platine), et musicien.
          </p>
          <p class="bio_content_text">
            Sa carrière qui commence au début des années 2000, il a enregistré tous les types de musiques, principalement acoustiques, et depuis une dizaine d'années se spécialise dans le mixage.
          </p>
          <p class="bio_content_text">
            Après des années sur différentes configurations, David mixe aujourd'hui sur un système hybride basé autour de <a class="text-link" href="https://www.avid.com/pro-tools" target="_blank">Protools</a> et de convertisseurs <a class="text-link" href="https://www.lynxstudio.com" target="_blank">Lynx</a> ainsi que des périphériques analogiques haut de gamme afin d'allier le meilleur du numérique et de l'analogique.
          </p>
          <p class="bio_content_text">
            Depuis 2020, David, en plus de son activité de mixage, a créé une <a class="text-link" href="https://youtube.com/@lessecretsduson?si=U4rmnjxt7XHmSuya" target="_blank">chaîne Youtube</a> spécialisée dans le domaine du son, proposant des conseils et des tests de matériel.
          </p>
      </div>
      <div id="bio_ytplayer" class="bio_content">
        <button v-if="!displayPlayer" @click="showPlayer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg><span>Extraits musicaux</span></button>
        <iframe
            v-else
            allow="fullscreen"
            src="https://www.youtube.com/embed?listType=playlist&list=PLkWklNhWZ3pMMB4cJJi0fZ1Ty59Gjf2hI"
        />

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BiographyPresentation',
  data() {
    return {
      electricBlue: "#4EBBC2",
      darkElectricBlue: "#557F82",
      smoothBlack: "#1F2222",
      smoothWhite: "#FFF9F3",
      displayPlayer: false,
      contentArrayDisk: []
    }
  },
  methods: {
    getImgUrl(a) {
      return require(`@/assets/${a}`);
    },
    showPlayer() {
      this.displayPlayer = true;
    }, 
    async loadArticlesJSON() {
      try {
        const response = await fetch('/data/content.json');
        if (response.ok) {
          const data = await response.json();
          this.contentArrayDisk = data.recompenses;
        } else {
          console.error('Erreur de chargement des données JSON');
        }
      } catch (error) {
        console.error('Erreur:', error);
      }
    }
  },
  beforeMount() {
    this.loadArticlesJSON()
  }
}
</script>

<style>
.text-link, .text-link:link, .text-link:visited {
  color: v-bind(electricBlue);
  outline: none;
  text-decoration: none;
}
.text-link:hover{
  text-decoration: underline;
}
#reward_container{
  margin: 3rem 0 5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 2rem;
}
.reward_content{
  margin: 0;
  display: flex;
  align-content: center;
  align-items: center;
  gap: 1rem;
}
.reward_content img{
  height: 5rem;
}
.reward_content figcaption{
  white-space: nowrap;
}
#bio_container{
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 20px;
}
.bio_content{
  width: 45%;
}
#bio_ytplayer{
  height: 27vh;
  width: 48vh;
  display: flex;
  align-content: center;
  align-items: center;
}
#bio_ytplayer button{
  margin: 0 auto;
  padding: 1rem 5vw;
  border: v-bind(electricBlue) 1rem;
  background-color: v-bind(smoothWhite);
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  align-content: center;
  gap: 0.5rem;
}
#bio_ytplayer span{
  color: v-bind(darkElectricBlue);
  font-weight: bold;
}
#bio_ytplayer svg{
  height: 2rem;
}
#bio_ytplayer path{
  fill: v-bind(darkElectricBlue);
}
#bio_ytplayer button:hover{
  background-color: v-bind(electricBlue);
  transition: background-color 0.5s ease-in-out;
}
#bio_ytplayer button:hover path{
  fill: v-bind(smoothWhite);
  transition: fill 0.5s ease-in-out;
}
#bio_ytplayer button:hover span{
  color: v-bind(smoothWhite);
  transition: color 0.5s ease-in-out;
}

#bio_ytplayer iframe{
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 890px) {
  #bio_container{
    flex-flow: column;
    gap: 3rem;
  }
  #bio_ytplayer{
    height: calc(85vw*9/16);
    width: 85vw;
  }
  #bio_container{
    gap: 0;
  }
  .bio_content{
    width: 100%;
  }
}
</style>